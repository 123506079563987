import React, { memo, useRef, useState, useCallback, useEffect } from 'react';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import tapImage from "../../assets/img/tap1.png";
import { TapAnimation } from './TapAnimation';

export const TapArea = memo(({ onTap, tapInfo, animationRef }) => {

    const lastTapTime = useRef(0);
    const { triggerHaptic } = useHapticFeedback();
    const [scaleQueue, setScaleQueue] = useState([]);
     const [tapAreaWidth, setTapAreaWidth] = useState(295);
        useEffect(() => {
        const updateWidth = () => {
            if (window.innerHeight < 715) {
                setTapAreaWidth(230); // Smaller width for shorter screens
            } else {
                setTapAreaWidth(295); // Default width for larger screens
            }
        };

        updateWidth(); // Call on mount
        window.addEventListener("resize", updateWidth); // Listen for window resize

        return () => {
            window.removeEventListener("resize", updateWidth); // Cleanup
        };
    }, []);
    const handleTap = useCallback((event) => {
        if(tapInfo.isLimitReached) {
             onTap(event);
            return

        }
        event.stopPropagation();

        // Debounce rapid taps (50ms)
        const now = Date.now();
        if (now - lastTapTime.current < 50) return;
        lastTapTime.current = now;
        const { clientX, clientY } = event;
        triggerHaptic();
          animateScale();
     
        animationRef?.current?.showNumberAnimation(clientX, clientY, tapInfo.coinPertap);
        onTap(event);
    }, [onTap, triggerHaptic, animationRef]);


    const animateScale = useCallback(() => {
        const animationId = Date.now(); 
        setScaleQueue((prevQueue) => [...prevQueue, animationId]);

        setTimeout(() => {
            setScaleQueue((prevQueue) => prevQueue.filter((id) => id !== animationId));
        }, 200); 
    }, []);
     const isScaling = scaleQueue.length > 0; 
    return (
        <div className="tap-area relative  mx-auto mt-6 flex justify-center items-center"  style={{ width: `${tapAreaWidth}px` }}>
            <TapAnimation ref={animationRef}  />

            {/* Shadow Wrapper */}
            <div
                className={`relative z-10 flex justify-center bg-[#69696b6e] items-center rounded-full shadow-glow `}
                style={{
                    width: 'fit-content',

                }}
            >
                <img
                    src={tapImage}
                    onClick={handleTap}
                    onContextMenu={(e) => e.preventDefault()}
                    className={`relative z-20 cursor-pointer rounded-full transform transition-transform duration-20 ease-in-out scale`}
                    alt="Tap Area"
                />
            </div>
        </div>
    );
});

TapArea.displayName = 'TapArea';