import React from 'react';

const TopBox = ({ 
  isActive = false, 
  children, 
  customClass = '' 
}) => {
  return (
    <div className="flex flex-col items-center justify-center relative ">
      <div 
        className={`w-full bg-main h-[73px] rounded-[12px] flex flex-col  items-center justify-center z-10 ${customClass}
        ${isActive ? 'border border-[#C121E5]' : ' border  border-main'}`}
      >
        {children}
      </div>
    </div>
  );
};

export default TopBox;
