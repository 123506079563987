import React, { memo } from 'react';
import 'react-circular-progressbar/dist/styles.css';

export const EarningProgress = memo(({ burnProgressData }) => {
  const eligibleDays=burnProgressData?.eligibleDays;
  const totalBurned = burnProgressData?.totalBurned || 0;
  const maxReferralCountForBurn = burnProgressData?.maxReferralCountForBurn || 1;
  const dailyBurnUsdPerReferral = burnProgressData?.dailyBurnUsdPerReferral || 0.05;
  const maxLimit = (maxReferralCountForBurn * dailyBurnUsdPerReferral)*eligibleDays;
  console.log('burnProgressData', burnProgressData);
 
  const percentage = (totalBurned / maxLimit) * 100;
  const remaining = maxLimit - totalBurned;
  
  return (
        <div className=" pt-2">
    
    <div className="w-full  mx-auto px-4 py-1 shadow-[0_0_12px_rgba(79,70,117,0.7)] border border-main rounded-xl ">
      

      <div className="flex justify-between items-center mb-1">
        <span className="text-white/80 text-xs">Burn Progress (last {eligibleDays || 30} days)</span>
        <span className="text-white text-[12px] font-bold">
          ${totalBurned?.toLocaleString()} / ${maxLimit?.toLocaleString()}
        </span>
      </div>
      
      <div className="relative h-2 bg-white/10 rounded-full overflow-hidden">
        <div 
          className={`absolute left-0 top-0 h-full rounded-full transition-all duration-300 ${
            percentage < 70 ? 'bg-white/20' : 'bg-[#008000]'
          }`}
          style={{ width: `${Math.min(percentage, 100)}%` }}
        />
      </div>
      
      <div className="flex justify-between items-center mt-1">
        <span className="text-white/70 text-xs">
          {percentage.toFixed(1)}% Complete
        </span>
        <span className={`text-xs font-medium ${
          remaining > 0 ? 'text-[#ddd]' : 'text-red-400'
        }`}>
          {remaining > 0 
            ? `$${remaining.toLocaleString()} Missed`
            : ' 100% Completed'
          }
        </span>
      </div>

      {/* Optional: Show time until reset */}
      {/* {remaining <= 0 && (
        <div className="mt-1 text-center text-xs text-white/60">
          Resets in: 12h 30m
        </div>
      )} */}

    </div>
    </div>
  );
});

EarningProgress.displayName = 'EarningProgress';