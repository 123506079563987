import React from 'react';
import coin from "../../assets/img/home/star-yellow.svg";
import earning from "../../assets/img/home/gift.svg";
import { Link } from 'react-router-dom';
import { UserIcon, ChevronRight, Ear } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { openLink } from '@telegram-apps/sdk';
import coinImage from "../../assets/img/CatsCoin.png";
import { useGame } from '../../context/GamContext';


const StarButton = ({ user, onClick }) => (
  <div onClick={onClick} className="flex items-center gap-1.5 mb-[-10px]  bg-main  border border-main px-3 py-1.5 rounded-full cursor-pointer">
    {user?.rank !== "Miner" && (
      <img src={coin} alt="star" className="w-5 h-5" />
    )}
    
    <span className="text-white font-semibold text-sm">{user?.rank} </span>
  </div>
);
export const EarnMoreButton = ({ user, gameState }) => {
  const handleOpenLink = () => {
     const userPanelLink = process.env.REACT_APP_USER_PANEL;
    const link = `${userPanelLink}/review?type=telegram&&auth=${user?.token}`;
    if (typeof openLink !== "undefined" && openLink.isAvailable()) {
      openLink(link, {
        tryBrowser: 'chrome',
      });
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="relative !mt-[6px]">
      {/* Glow effect wrapper */}
      {/* <div className="absolute inset-0 bg-[#0D0D0D] rounded-xl blur-lg opacity-50" /> */}

      <button className="relative z-50 w-full px-4 py-1 bg-[#0D0D0D] rounded-full 
                transition-all duration-300
                shadow-glow  border border-secondary border-2">
        <div className="grid grid-cols-[1fr_auto_1fr] items-center gap-4">
          {/* Left side */}
          <div onClick={handleOpenLink} className="flex items-center gap-3 whitespace-nowrap">
            <img src={earning} alt="star" className="w-6 h-6" />
            <div className="flex flex-col leading-tight">
                  <span className="text-white font-semibold text-[11px]">Earn More</span>
                  <span className="text-white/70 text-[10px]">Complete tasks to earn more</span>
                </div>

            <ChevronRight className="text-white/70 h-5 w-5" />
          </div>

          {/* Separator line */}
          <div className="w-px h-6 bg-white/20" />

          {/* Right side */}
          <div className="flex items-center gap-1 justify-end">
            <img src={coinImage} alt="coin" className="w-4 h-4" />
            <span className="text-white font-semibold text-[12px]">
              {gameState?.coinBalance}
            </span>
          </div>
        </div>
      </button>
    </div>
  );
};
export const HeaderArea = ({ user }) => {
    const { gameState } = useGame();

  const navigate = useNavigate();
  const handlOnclickRank = () => {
    if (user?.rank === "Miner") {
      navigate('/rewards');
    }

    navigate('/rank-achievement');
  }
  console.log("sadasdsad",user);
  return (
    <div className="relative w-full space-y-3  pt-2 pb-1">
      {/* Profile Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          {/* Profile Picture */}
          {user?.photoUrl ? (
            <img
              src={user.photoUrl}
              className="w-11 h-11 rounded-xl object-cover"
              alt="Profile"
            />
          ) : (
            <div className="w-11 h-11 rounded-xl flex items-center justify-center bg-[#2A1D5C]">
              <UserIcon className="w-6 h-6 text-white/90" />
            </div>
          )}

          {/* Name & ID */}
          <div className="flex flex-col">
            <span className="text-white font-semibold">
              {user?.name ? user.name.split(' ')[0] : 'William'}
            </span>
            <span className="text-gray-400 text-sm">
              {user?.userId || '346166'}
            </span>
          </div>
        </div>

        <StarButton user={user} onClick={handlOnclickRank} />
      </div>

      {/* Earn More Button */}
      <EarnMoreButton gameState={gameState} user={user} />


    </div>
  );
};

export default HeaderArea;
