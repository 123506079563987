import React, { memo } from 'react';
import 'react-circular-progressbar/dist/styles.css';

export const DailyLimitIndicator = memo(({ dailyCoinsEarned, dailyLimit }) => {
  const percentage = (dailyCoinsEarned / dailyLimit) * 100;
  const remaining = dailyLimit - dailyCoinsEarned;
  
  return (
        <div className="absolute left-0 right-0 bottom-[78px] px-2">
    
    <div className="w-full  mx-auto px-4 py-1 shadow-[0_0_12px_rgba(79,70,117,0.7)] border border-main rounded-xl ">
          <div className="absolute inset-0 bg-[#2A1D5C] rounded-xl blur-lg opacity-10" />

      <div className="flex justify-between items-center mb-1">
        <span className="text-white/80 text-xs">Daily Progress</span>
        <span className="text-white text-[12px] font-bold">
          {dailyCoinsEarned.toLocaleString()} / {dailyLimit.toLocaleString()}
        </span>
      </div>
      
      <div className="relative h-2 bg-white/10 rounded-full overflow-hidden">
        <div 
          className={`absolute left-0 top-0 h-full rounded-full transition-all duration-300 ${
            percentage < 80 ? 'bg-white/20' : 'bg-white/30'
          }`}
          style={{ width: `${Math.min(percentage, 100)}%` }}
        />
      </div>
      
      <div className="flex justify-between items-center mt-1">
        <span className="text-white/70 text-xs">
          {percentage.toFixed(1)}% Complete
        </span>
        <span className={`text-xs font-medium ${
          remaining > 0 ? 'text-[#ddd]' : 'text-red-400'
        }`}>
          {remaining > 0 
            ? `${remaining.toLocaleString()} Coins remaining`
            : 'Daily limit reached'
          }
        </span>
      </div>

      {/* Optional: Show time until reset */}
      {/* {remaining <= 0 && (
        <div className="mt-1 text-center text-xs text-white/60">
          Resets in: 12h 30m
        </div>
      )} */}

    </div>
    </div>
  );
});

DailyLimitIndicator.displayName = 'DailyLimitIndicator';